import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateBareMetalProjectConfiguration from './CreateBareMetalProjectConfiguration__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateBareMetalProjectConfigurationContainer = connect(
  null,
  mapDispatchToProps,
)(CreateBareMetalProjectConfiguration);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateBareMetalProjectConfigurationContainer
      projectId={props.projectId}
      configurations={props.bareMetalConfigurations}
      options={props.options}
      railsContext={context}
      status={props.status}
      reduxfunction={props.reduxfunction}
    />
  </ReduxProvider>
);
